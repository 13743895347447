// Models
import { HostbiStatus } from 'src/app/modules/hostbi/models/hostbi';

export class HostbiServer {
    id = 0;
    name = '';    
    ipv4 = '';
    ipv6 = '';
    host = '';
    info: object = {};
    status: HostbiStatus = new HostbiStatus();
    services: Array<HostbiServerService> = [];
    // "packages": [ { "name": "profesional", "bandwidth": "5000", "storage": "1000" } ]
    available = true;
    availability: Record<string, number> = {};

    constructor(parameters?: any) {
        if(typeof parameters !== 'undefined' && parameters != null){
            this.id = parameters.id;
            this.name = parameters.name;
            this.ipv4 = parameters.ipv4;
            this.ipv6 = parameters.ipv6;
            this.host = parameters.host;
            this.info = this.infoVarSet(parameters.info);
            this.status = new HostbiStatus(parameters.status);
            this.available = parameters.available;
            this.availability = parameters.availability;

            if(typeof parameters.services != 'undefined' && parameters.services.length > 0) {
                parameters.services.forEach((service: any, index: number) => {
                    this.services.push(new HostbiServerService(service));
                });
            }
        }
    }

    get isAvailable(){
        for (const key of Object.keys(this.availability)) {
            const value = this.availability[key];
    
            if (value < 2) {
                return 'unavailable';
            } else if (value < 4) {
                return 'partial';
            }
        }

        return '';
    }

    infoVarSet(parameters: any = {}): object{
        const defaultParams: any = {
            packages: [],
            actions: []
        };
        
        Object.keys(parameters).forEach((param) => {
            Object.keys(defaultParams).forEach((defaultParam) => {
                if(param == defaultParam){
                    defaultParams[param] = parameters[param];
                } else {
                    // defaultParams[param] = defaultParams[param];
                }
            });
        })

        return defaultParams;
    }
}

export class HostbiServerService {
    name = '';
    port = 0;
    status = false;

    constructor(parameters?: any) {
        if(typeof parameters !== 'undefined'){
            this.name = parameters.name;
            this.port = parameters.port;
            this.status = parameters.status;
        }
    }
}
