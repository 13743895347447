import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { validateDomain, validateIPv4, validateIPv6, validateSubdomain } from "src/app/library/utils/form-validators";
import { isEmpty, isUndefined } from "src/app/library/utils/functions";

function validateDefault(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      //let validationResult: ValidationErrors | null = () ? null : { invalid: true };
      let validationResult: ValidationErrors | null = null;

      if (isEmpty(validationResult)) {
        validationResult = null;
      }

      return validationResult;
    };
}

export const DOMAIN_RECORD_TYPES = ['A','AAAA','CAA','CERT','CNAME','DNSKEY','DS','HTTPS','LOC','MX','NAPTR','NS','PTR','SMIMEA','SRV','SSHFP','SVCB','TLSA','TXT','URI'];

export type DomainRecordType = typeof DOMAIN_RECORD_TYPES[number];

export interface DomainRecordInput{
    name: string;
    label: string;
    legend: string;
    mandatory: boolean;
    validate: () => ValidatorFn;
}

export const DOMAIN_RECORD_INPUTS: {[key: string] : DomainRecordInput} = {
    name: {
        name: 'name',
        label: 'Nombre',
        legend: 'Utilice @ para la raíz',
        mandatory: true,
        validate: validateDefault,
    },
    content: {
        name: 'content',
        label: 'Valor',
        legend: '',
        mandatory: false,
        validate: validateDefault,
    },
    ttl: {
        name: 'ttl',
        label: 'TTL',
        legend: '',
        mandatory: false,
        validate: validateDefault,
    },
    priority: {
        name: 'priority',
        label: 'Prioridad',
        legend: '0 - 65.535',
        mandatory: false,
        validate: validateDefault,
    },
    proxied: {
        name: 'proxied',
        label: 'Usar proxy de Cloudflare',
        legend: '',
        mandatory: false,
        validate: validateDefault,
    }
}

export const DOMAIN_RECORD_INPUTS_STRUCTURE: {[key: string]: {[key: string]: { mandatory?: boolean, label?: string, legend?: string, validate?: () => ValidatorFn }}} = {
    A: { 
        name: { }, 
        content: { mandatory: true, label: 'Dirección IPv4', validate: validateIPv4 }, 
        proxied: { mandatory: true }
    },
    AAAA: {
        name: { },
        content: { mandatory: true, label: 'Dirección IPv6', validate: validateIPv6 },
        proxied: { mandatory: false }
    },
    CNAME: {
        name: { },
        content: { mandatory: true, label: 'Destino', legend: 'Por ej., www.example.com', validate: validateSubdomain },
        proxied: { mandatory: false }
    },
    MX: {
        name: { },
        content: { mandatory: true, label: 'Servidor de correo', legend: 'Por ej., mx1.example.com', validate: validateSubdomain },
        ttl: { mandatory: false },
        priority: { mandatory: true }
    },
    NS: {
        name: { },
        content: { mandatory: true, label: 'Servidor de nombres', legend: 'Por ej., ns1.example.com', validate: validateSubdomain },
        ttl: { mandatory: false }
    },
    PTR: {
        name: { },
        content: { mandatory: true, label: 'Nombre de dominio', legend: 'Por ej., www.example.com', validate: validateSubdomain },
        ttl: { mandatory: false }
    },
    TXT: {
        name: { },
        content: { mandatory: true, label: 'Contenido' },
        ttl: { mandatory: true }
    },
};

export class DomainRecordTypes {
    type: DomainRecordType = 'A';
    types = Object.keys(DOMAIN_RECORD_INPUTS_STRUCTURE);
    inputs: {[key:string]: DomainRecordInput} = {};

    constructor() {
        this.set(this.type);
    }

    set(type: DomainRecordType): DomainRecordTypes{
        this.type = type;
        this.inputs = {};

        Object.keys(DOMAIN_RECORD_INPUTS_STRUCTURE[type]).forEach((key) => {
            const structure = DOMAIN_RECORD_INPUTS_STRUCTURE[type][key];
            const input = DOMAIN_RECORD_INPUTS[key];


            this.inputs[key] = {
                name: input.name,
                label: structure.label ?? input.label,
                legend: structure.legend ?? input.legend,
                mandatory: structure.mandatory ?? input.mandatory,
                validate: structure.validate ?? input.validate,
            };

            console.log(key, [structure, this.inputs[key]])
        });

        return this;
    }

    check(input: string): boolean{
        return typeof this.inputs[input] !== 'undefined';
    }
    
    input(name: string): DomainRecordInput{
        return !isUndefined(this.inputs[name]) ? this.inputs[name] : { name: '', label: '', legend: '', mandatory: false, validate: validateDefault};
    }

    /*get name(): boolean {
        
        
        return ['MX','TLSA','SVCB','SSHFP','SMIMEA','NAPTR','LOC','HTTPS','DS','DNSKEY','CERT','CCA','TXT','PTR','NS','CNAME','AAAA','A'].includes(this.type);
    }
    
    get data(): boolean {
        return ['SRV','URI'].includes(this.type);
    }

    get content(): boolean {
        return ['MX','TXT','PTR','NS','CNAME','AAAA','A'].includes(this.type);
    }

    get ttl(): boolean {
        return true;
    }

    get priority(): boolean {
        return ['MX','URI'].includes(this.type);
    }

    get proxied(): boolean {
        return ['A','AAAA','CNAME'].includes(this.type);
    }*/
}