import { Component, Input, OnInit, Type } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { UiModalbox } from 'src/app/library/components/ui-modalbox';
import { formValidate, formValidateInit } from 'src/app/library/utils/form-validators';
import { DomainRecordTypes } from 'src/app/modules/hostbi/models/hostbi-domain';
import { HostbiAccount, HostbiAccountDomain, HostbiAccountDomainRecord } from 'src/app/modules/hostbi/models/hostbi-account';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { HostbiDomainsService } from 'src/app/modules/hostbi/services/hostbi-domains.service';


@Component({
  selector: 'app-hostbi-account-domain-record-form',
  templateUrl: './account-domain-record-form.component.html',
  styleUrls: ['./account-domain-record-form.component.scss']
})
export class HostbiAccountDomainRecordFormComponent implements OnInit {
  @Input() account = new HostbiAccount();
  @Input() domain = new HostbiAccountDomain();
  @Input() record = new HostbiAccountDomainRecord();

  public recordTypes = new DomainRecordTypes();

  public formEdit = false;
  public formData: FormGroup;
  public formValidates: any;
  public formInputsErrors: object = {
    type: {},
    name: {},
    content: {
      invalid: 'Contenido inválido',
      domainInvalid: 'Dominio inválido',
      ipv4Invalid: 'Dirección ip inválida',
      ipv6Invalid: 'Dirección ip inválida'
    },
    ttl: {},
    priority: {},
    proxied: {},
  };

  constructor(
    private uiService: UiService,
    private modalbox: UiModalbox,
    private formBuilder: FormBuilder,
    private hostbiDomainsService: HostbiDomainsService
  ) {
    this.formData = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.formEdit = (this.record.id > 0) ? true : false;

    const formData: {[key: string] : FormControl} = {
      name: new FormControl((this.formEdit) ? this.record.name : '', [Validators.required]),
      content: new FormControl((this.formEdit) ? this.record.content : '', [Validators.required]),
      ttl: new FormControl((this.formEdit) ? this.record.ttl : '1', [Validators.required]),
      priority: new FormControl((this.formEdit) ? this.record.priority : '0', [Validators.required]),
      proxied: new FormControl((this.formEdit) ? this.record.proxied : false, [Validators.required]),
    }

    if(!this.formEdit){
      formData['type'] = new FormControl(this.recordTypes.type, [Validators.required]);
    }

    this.formData = this.formBuilder.group(formData);

    if(!this.formEdit){
      this.typeChange();
    }

    this.formValidates = formValidateInit(this.formData);
  }

  close(): void {
    this.modalbox.close();
  }

  typeChange(){
    this.recordTypes.set(this.formData.value.type);

    this.formData.patchValue({
      name: '',
      content: '',
      ttl: '1',
      priority: '0',
      proxied: false,
    });

    Object.keys(this.formData.value).forEach((input) => {
      this.formData.controls[input].clearValidators();

      if(this.recordTypes.check(input)){
        const validates: any = [this.recordTypes.inputs[input].validate()];

        if(this.recordTypes.inputs[input].mandatory){
          validates.push(Validators.required);
        }
        console.log(input, validates)
        this.formData.controls[input].setValidators(validates);
      }

      this.formData.controls[input].updateValueAndValidity();
    });
  }

  continue(): void{
    formValidate(this.formInputsErrors, this.formData, this.formValidates); 

    if (this.formData.valid) {
      if(this.formEdit){
        this.update();
      } else {
        this.create();
      }    
    } else {
      this.formData.markAllAsTouched();
      this.uiService.snackbar('Algunos datos no están completos');
    }
  }

  create(){
    this.uiService.loader(true);
    
    this.hostbiDomainsService.whitWorkspace().recordCreate(this.account.id, this.domain.id, { 
      type: this.formData.value.type,
      name: this.formData.value.name,
      content: this.formData.value.content,
      ttl: this.formData.value.ttl,
      priority: this.formData.value.priority,
      proxied: this.formData.value.proxied,
    }).then((success: HttpResponseData) => {
      this.modalbox.close(success.result);

      this.uiService.loader(false);
      this.uiService.snackbar('El registro se creó correctamente.');
    }, () => {
      this.uiService.loader(false);
      this.uiService.snackbar('No fué posible crear el registro.', 'error');
    });
  }

  update(){
    this.uiService.loader(true);
    
    this.hostbiDomainsService.whitWorkspace().recordUpdate(this.account.id, this.domain.id, this.record.id, { 
      name: this.formData.value.name,
      content: this.formData.value.content,
      ttl: this.formData.value.ttl,
      priority: this.formData.value.priority,
      proxied: this.formData.value.proxied,
    }).then((success: HttpResponseData) => {
      this.modalbox.close(success.result);

      this.uiService.loader(false);
      this.uiService.snackbar('El registro se actualizó correctamente.');
    }, () => {
      this.uiService.loader(false);
      this.uiService.snackbar('No fué posible actualizar el registro.', 'error');
    });
  }
}
