import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { isNull } from '../utils/functions';

@Directive({
  selector: '[inputInt]'
})
export class UiInputIntDirective {
  constructor() { }

  @HostListener('keypress', ['$event'])
  onInput(event: any) {
    const inputChar = String.fromCharCode(event.which ? event.which : event.keyCode);

    if (!/[0-9]/.test(inputChar)) {
      event.preventDefault();

      return false;
    }

    return true;
  }
}

@Directive({
  selector: '[inputDecimal]'
})
export class UiInputDecimalDirective {
  @Input() maxlength: string|number = 9999999999;

  constructor(private el: ElementRef) {}

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const value = this.el.nativeElement.value;
        const key = (event.which) ? event.which : event.keyCode;
        const newvalue = value + String.fromCharCode(key);

        if(value === ''){
          if(key === 110 || key === 190){
            event.preventDefault();
          }
        }

        if(
          key !== 8 && key !== 46 &&
          key !== 109 && key !== 110 &&
          key !== 189 && key !== 190 &&
          key > 31 && (key < 48 || key > 57) &&
          (key !== 37 && key !== 39) &&
          (key < 96 || key > 105)
        ) {
          event.preventDefault();
        }

        const parts = newvalue.split('.');

        if(parts.length > 1 && (key === 110 || key === 190)){
          event.preventDefault();
        }

        if(key !== 8){
          if(typeof parts[1] !== 'undefined'){
            if(parts[1].length > 2){
              event.preventDefault();
            }
          }

          const maxLength = (parts.length > 1) ? Number(this.maxlength) + 3 : this.maxlength;

          if(value.length >= maxLength && key !== 37 && key !== 39 && key !== 110 && key !== 190 && (parts.length && key !== 8)){
            event.preventDefault();
          }
        }

        return true;
    }
}

@Directive({
  selector: '[inputMaxLength]'
})
export class UiInputMaxLengthDirective {
  @Input() inputMaxLength = '0';

  constructor(private el: ElementRef) { }

  @HostListener('keypress', ['$event'])
  onInput(event: any) {
    const value = this.el.nativeElement.value;

    if (!isNull(value) && value.length >= Number(this.inputMaxLength)) {
      event.preventDefault();

      return false;
    }

    return true;
  }
}

@Directive({
  selector: '[inputClearSpaces]',
})
export class UiInputClearSpacesDirective {
  constructor(private el: ElementRef) { }

  @HostListener('keypress', ['$event'])
  onKeypress() { this.clear(); }

  @HostListener('blur', ['$event'])
  onBlur(): void { this.clear(); }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: any) { 
    // Esto evita que se pueda borrar en el campo
    // event.preventDefault();
  }

  clear(){
    const value = this.el.nativeElement.value;
    const valueTrim = value.replace(/ /g,'');
    
    if(value !== valueTrim) {
      this.el.nativeElement.value = valueTrim;
    }
  }
}

@Directive({
  selector: '[inputCode]'
})
export class UiInputCodeDirective {
  constructor() { }

  @HostListener('keypress', ['$event'])
  onInput(event: any) {
    const inputChar = String.fromCharCode(event.which ? event.which : event.keyCode);

    if (!/^[A-Za-z0-9\-/!¡"#$%&/()=¿?+*_<>[\]]+$/.test(inputChar)) {
      event.preventDefault();

      return false;
    }

    return true;
  }
}

@Directive({
  selector: '[invoceNumberMask]',
})
export class UiInvoiceNumberMaskDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: any) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event: any, backspace: boolean): void {
    if (event === null) { return; }

    let newVal = event.replace(/\D/g, '');

    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 4) {
      newVal = newVal.replace(/^(\d{0,4})/, '$1');
    } else if (newVal.length <= 12) {
      newVal = newVal.replace(/^(\d{0,4})(\d{0,8})/, '$1-$2');
    } else if (newVal.length > 12) {
      newVal = newVal.substring(0, 12).replace(/^(\d{0,4})(\d{0,8})/, '$1-$2');
    }

    this.ngControl.valueAccessor?.writeValue(newVal);
  }
}
